// src/Slideshow.js
import React, { useState, useEffect } from 'react';
import Menu from '../Menu/Menu';
import ShotsDisplay from './ShotsDisplay';
import './Slideshow.css';

const Slideshow = () => {
  const [items, setItems] = useState(['Side To Side', 'ForeHand Left', 'ForeHand Right', 'ForeHand Toe Drag', 'Back Hand Toe Drags', 'Horizontal Figure 8', 'Vertical Figure 8', 'Shoot Top Right', 'Shoot Top Left', 'Shoot Bottom Right', 'Shoot Bottom Left', 'Slap Shot', 'Snap Shot Left', 'Snap Shot Right']);
  const [currentItem, setCurrentItem] = useState('');
  const [intervalTime, setIntervalTime] = useState(10000);
  const [input, setInput] = useState('');
  const [newItem, setNewItem] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [showShots, setShowShots] = useState(false);
  const [remainingItems, setRemainingItems] = useState([...items]);

  // Filter only shot-related items
  const shots = items.filter(item => item.toLowerCase().includes('shoot') || item.toLowerCase().includes('shot'));

  useEffect(() => {
    if (remainingItems.length > 0) {
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * remainingItems.length);
        const nextItem = remainingItems[randomIndex];
        setCurrentItem(nextItem);
        const updatedItems = remainingItems.filter((item) => item !== nextItem);
        setRemainingItems(updatedItems);
      }, intervalTime);

      return () => clearInterval(interval);
    } else {
      setCurrentItem('Good Job!');
    }
  }, [remainingItems, intervalTime]);

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
      setItems([...items, newItem]);
      setRemainingItems([...remainingItems, newItem]);
      setNewItem('');
    }
  };

  const handleUpdateInterval = () => {
    const newInterval = parseInt(input);
    if (!isNaN(newInterval) && newInterval > 0) {
      setIntervalTime(newInterval);
    }
  };

  return (
    <div className="slideshow-container">
      <div
        className="hamburger"
        role="button"
        aria-label="Toggle menu"
        onClick={() => setMenuOpen(!menuOpen)}
        tabIndex={0}
        onKeyPress={(e) => e.key === 'Enter' && setMenuOpen(!menuOpen)}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <Menu
        menuOpen={menuOpen}
        newItem={newItem}
        input={input}
        setNewItem={setNewItem}
        setInput={setInput}
        handleAddItem={handleAddItem}
        handleUpdateInterval={handleUpdateInterval}
        showShots={showShots}
        setShowShots={setShowShots}
      />

      {/* Conditionally Render the ShotsDisplay or the Regular Slideshow */}
      {showShots ? (
        <ShotsDisplay shots={shots} intervalTime={intervalTime} />
      ) : (
        <div id="slideshow-item">{currentItem}</div>
      )}
    </div>
  );
};

export default Slideshow;
