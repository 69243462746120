// src/ShotsDisplay.js
import React, { useEffect, useState } from 'react';

const ShotsDisplay = ({ shots, intervalTime }) => {
  const [currentShot, setCurrentShot] = useState('');
  const [remainingShots, setRemainingShots] = useState([...shots]);

  useEffect(() => {
    if (remainingShots.length > 0) {
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * remainingShots.length);
        const nextShot = remainingShots[randomIndex];
        setCurrentShot(nextShot);

        const updatedShots = remainingShots.filter((shot) => shot !== nextShot);
        setRemainingShots(updatedShots);
      }, intervalTime);

      return () => clearInterval(interval);
    } else {
      setCurrentShot('All Shots Completed!');
    }
  }, [remainingShots, intervalTime]);

  return (
    <div className="shots-display">      
      <div id="current-shot">{currentShot}</div>
    </div>
  );
};

export default ShotsDisplay;
