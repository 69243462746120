// src/App.js
import React from 'react';
import './App.css';
import Slideshow from './SlideShow/SlideShow';

function App() {
  return (
    <div className="App">
      <Slideshow />
    </div>
  );
}

export default App;
