// src/Menu.js
import React from 'react';

const Menu = ({ menuOpen, newItem, input, setNewItem, setInput, handleAddItem, handleUpdateInterval, showShots, setShowShots }) => {
  return (
    menuOpen && (
      <div className="menu">
        <h2>Edit List</h2>
        <input
          type="text"
          placeholder="Add word or number"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
        />
        <button onClick={handleAddItem}>Add</button>

        <div style={{ marginTop: '20px' }}>
          <h2>Set Interval (ms)</h2>
          <input
            type="number"
            placeholder="Enter milliseconds"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button onClick={handleUpdateInterval}>Set Interval</button>
        </div>

        <div style={{ marginTop: '20px' }}>
          <h2>Display Options</h2>
          <label>
            <input
              type="checkbox"
              checked={showShots}
              onChange={() => setShowShots(!showShots)}
            />
            Show Shots Only
          </label>
        </div>
      </div>
    )
  );
};

export default Menu;
